import React, { useState, useEffect, useRef } from 'react';

import lookRight from './Imgs/look-right.png';
import lookLeft from './Imgs/look-left.png';
import lookUp from './Imgs/look-top.png';
import lookDown from './Imgs/look-bottom.png';
import lookFront from './Imgs/look-front.png';
import lookDefault from './Imgs/look-top-right.png';
import lookTopRight from './Imgs/look-top-right.png';
import lookBottomRight from './Imgs/look-bottom-right.png';
import lookTopLeft from './Imgs/look-top-left.png';
import lookBottomLeft from './Imgs/look-bottom-left.png';

const EyeFollow = () => {
  const [direction, setDirection] = useState('default');
  const imageRef = useRef(null);

  const imageMap = {
    right: lookRight,
    left: lookLeft,
    up: lookUp,
    down: lookDown,
    default: lookDefault,
    front: lookFront,
    topRight: lookTopRight,
    bottomRight: lookBottomRight,
    topLeft: lookTopLeft,
    bottomLeft: lookBottomLeft,
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;

      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();

        const widthThird = rect.width / 3;
        const heightThird = rect.height / 3;

        const xPos = clientX - rect.left;
        const yPos = clientY - rect.top;

        // Determine direction based on mouse position relative to the image
        if (xPos > 2 * widthThird && yPos < heightThird) {
          setDirection('topRight');
        } else if (xPos > 2 * widthThird && yPos > 2 * heightThird) {
          setDirection('bottomRight');
        } else if (xPos < widthThird && yPos < heightThird) {
          setDirection('topLeft');
        } else if (xPos < widthThird && yPos > 2 * heightThird) {
          setDirection('bottomLeft');
        } else if (xPos > 2 * widthThird) {
          setDirection('right');
        } else if (xPos < widthThird) {
          setDirection('left');
        } else if (yPos < heightThird) {
          setDirection('up');
        } else if (yPos > 2 * heightThird) {
          setDirection('down');
        } else if (xPos > widthThird && xPos < 2 * widthThird && yPos > heightThird && yPos < 2 * heightThird) {
          setDirection('front');
        } else {
          setDirection('default');
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div>
      <img ref={imageRef} className="EyeTrackingImage" src={imageMap[direction]} alt="Look direction" />
    </div>
  );
};

export default EyeFollow;
