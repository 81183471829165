import React, { useEffect, useState } from "react";
import Logo from "./Imgs/Logo.png";

function Header() {
  const [activeSection, setActiveSection] = useState("Hero"); // Default active section is "Hero"

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY;

      let foundActive = false; // Flag to track if a section is found as active

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        const sectionId = section.getAttribute("id");

        if (
          scrollPosition >= sectionTop - 200 &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(sectionId); // Set active section by ID
          foundActive = true;
        }
      });

      // If no section is found in view, keep the last active section
      if (!foundActive) {
        setActiveSection((prev) => prev);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className="App-header">
      <img src={Logo} width={65} alt="Logo" />
      <div className="ButtonsContainer">
        <a href="#Hero" className={activeSection === "Hero" ? "active" : ""}>
          Home
        </a>
        <a href="#About" className={activeSection === "About" ? "active" : ""}>
          About
        </a>
        <a
          href="#ProjectsSep"
          className={activeSection === "Projects" ? "active" : ""}
        >
          Projects
        </a>
        <a
          href="#Contact"
          className={activeSection === "Contact" ? "active" : ""}
        >
          Contact
        </a>
      </div>
      <div className="HeaderFiller"></div>
    </header>
  );
}

export default Header;
