import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const FullscreenImage = ({ thumbnailSrc, fullImageSrc, alt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {/* Low-quality thumbnail */}
      <img
        src={thumbnailSrc}
        alt={alt}
        onClick={openModal}
        style={{
          cursor: 'pointer',
          display: 'block',
          margin: '0 auto',
          transition: 'transform 0.3s ease-in-out',
        }}
        onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
        onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
      />

      {/* High-quality fullscreen image (Rendered outside transformed parent using a portal) */}
      {isOpen &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999, // Ensure it's on top
            }}
            onClick={closeModal} // Close modal when clicking outside the image
          >
            <img
              src={fullImageSrc}
              alt={alt}
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </div>,
          document.body // Render outside the transformed parent
        )}
    </>
  );
};

export default FullscreenImage;