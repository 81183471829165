import { useEffect, useState, useRef } from "react";

const useInView = (threshold = 0.2, loading) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Function to trigger the animation on the element
  const triggerAnimation = () => {
    if (ref.current) {
      ref.current.classList.add("animate");
      ref.current.querySelectorAll(
        ".fade-in, .fade-in-right, .fade-in-left, .fade-in-bottom, .zoom-in"
      ).forEach((child) => child.classList.add("animate"));
    }
  };

  useEffect(() => {
    if (loading) return; // Do nothing if loading is true

    // Initialize IntersectionObserver
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          triggerAnimation();
        }
      },
      { threshold }
    );

    // Start observing the element
    if (ref.current) {
      observer.observe(ref.current);
    }

    // Handle screen resize event
    const handleResize = () => {
      if (ref.current) {
        // Re-trigger the animation check on resize
        triggerAnimation();
      }
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up on component unmount or when ref changes
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [loading]); // Re-run the effect if the loading state changes

  return [ref, isVisible];
};

export default useInView;
